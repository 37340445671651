import React, { useState } from 'react'
import Slider from "react-slick";
import styled from '@emotion/styled'

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <PrevArrowStyle
      className={className}
      onClick={onClick}
      style={{ 
        ...style 
      }}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <NextArrowStyle
      className={className}
      onClick={onClick}
      style={{ 
        ...style
      }}
    />
  );
}

export default function HeroSlider(props) {
  const [imagesLoaded, setImagesLoaded] = useState(false)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <SliderContainer key={imagesLoaded} >
      <Slider {...settings}>
        {
          props.items.map((item, index) => 
          <SliderItem key={index} >
            <div>
              <img 
                src={item}
                onLoad={
                  () => {
                    if (index === props.items.length - 1 && !imagesLoaded) {
                      setImagesLoaded(true)
                    }
                  }
                }
                />
            </div>
            </SliderItem> 
            )
          }
      </Slider>
    </SliderContainer>
  )
}

const SliderContainer = styled.div`
  position: relative;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
  *{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const SliderItem = styled.div`
  display: flex!important;
  align-items: center;
  justify-content: center;
  img{
    width: 100%;
    margin: 0;
  }
`

const PrevArrowStyle = styled.div`
  display: block;
  z-index: 99;
  position: absolute;
  left: 0;
  &::before {
    color: black
  }
`

const NextArrowStyle = styled.div`
  display: block;
  z-index: 99;
  position: absolute;
  right: 0;
  &::before {
    color: black
  }
`