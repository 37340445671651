import styled from '@emotion/styled'

export const NoModelSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  p{
    font-size: 18px;
  }
`
export const ModelContent = styled.div`
  position: relative;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "desc ."
    ". specs"
    "gallery gallery"
  ;
  row-gap: 40px;
  column-gap: 20px;
  padding: 40px 70px;
  img {
    width: 100%;
  }
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "slider"
      "desc"
      "specs"
    ;
    padding: 30px;
    row-gap: 20px;
  }
`
export const ModelTitle = styled.div`
  grid-area: desc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3{
    font-weight: 800;
    font-size: 50px;
    color: #1c356e;
    text-transform: uppercase;
    margin-bottom: 14px;
  }
  h5{
    font-weight: 800;
    font-size: 27px;
    color: #1c356e;
    text-transform: uppercase;
    margin-bottom: 14px;
  }
  p{
    font-size: 14px;
    font-weight: 500;
  }
  pre{
    font-size: 14px;
    font-weight: 500;
    max-width: 48ch;
  }
`

export const SpecsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: specs;
  h4{
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 16px;
  }
`

export const Specs = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 12px;
`

export const SpecsItem = styled.div`
  display: flex;
  max-height: 42px;
  img {
    height: 100%;
    width: auto;
    margin-right: 4px;
  }
  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 4px;
    h5 {
      font-size: 16px;
      font-weight: 500;
    }
    p {
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 400;
    }
  }
  a {
    background-color: #1c356e;
    font-size: 13px;
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    height: 27px;
    padding: 4px 12px;
    border-radius: 8px;
    letter-spacing: 1px;
  }
`

export const GalleryContainer = styled.div`
  grid-area: gallery;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h5{
    display: block;
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 500;
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`

export const Gallery = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 160px;
  img {
    max-width: 240px;
    height: auto;
  }
`
