import { css } from '@emotion/react'

export const GlobalStyle = css`
  #root{
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  * {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    box-sizing: border-box;
  }
  body {
    background-color: #0e2854;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`