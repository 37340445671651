import React, { useRef, useEffect, useState } from 'react'

export default function Img(props) {
  const ref = useRef()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (ref.current && (ref.current.naturalWidth || ref.current.naturalWidth)) {
      setLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (loaded) {
      props.onImageLoad()
    }
  }, [loaded])
  
  
  return (
    <img onLoad={() => setLoaded(true)} ref={ref} src={props.src} alt="" />
  )
}
