import React, { useState, useEffect, useContext } from 'react'
import Slider from "react-slick";
import styled from '@emotion/styled'
import { AppContext } from '../App'
import Img from './Img';
import { useContentful } from '../hooks/useContentful';

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <PrevArrowStyle
      className={className}
      onClick={onClick}
      style={{ 
        ...style 
      }}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <NextArrowStyle
      className={className}
      onClick={onClick}
      style={{ 
        ...style
      }}
    />
  );
}

export default function CustomSlider(props) {
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [ modelIsLoading, setModelIsLoading ] = useContext(AppContext);
  const { 
    boatsData: { selectedModelNumberOfImages, numberOfLoadedImages }, 
    actions: { setNumberOfLoadedImages }
  } = useContentful();

  useEffect(() => {
    if (selectedModelNumberOfImages === numberOfLoadedImages) {
      setModelIsLoading(false)
    }
  }, [selectedModelNumberOfImages, numberOfLoadedImages])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <SliderContainer key={imagesLoaded} >
      <Slider {...settings}>
        {
          props.items.map((item, index) => 
          <SliderItem key={index} >
            <div>
                <Img 
                  key={item.sys.id} 
                  onImageLoad={() => {
                    if (modelIsLoading) {
                      setImagesLoaded(true)
                    }
                    if (index + 1 > numberOfLoadedImages) {
                      setNumberOfLoadedImages(index + 1)
                    }
                    if (numberOfLoadedImages === selectedModelNumberOfImages) {
                      setModelIsLoading(false)
                    }
                  }} 
                  src={item.fields.file.url} 
                  alt={item.name} 
                />
            </div>
            </SliderItem> 
            )
          }
      </Slider>
    </SliderContainer>
  )
}

const SliderContainer = styled.div`
  position: relative;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
  *{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const SliderItem = styled.div`
  display: flex!important;
  align-items: center;
  justify-content: center;
  img{
    max-width: 400px;
    margin: 0;
  }
`

const PrevArrowStyle = styled.div`
  display: block;
  z-index: 98;
  position: absolute;
  left: 0;
  &::before {
    color: black
  }
`

const NextArrowStyle = styled.div`
  display: block;
  z-index: 98;
  position: absolute;
  right: 0;
  &::before {
    color: black
  }
`